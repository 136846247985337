import axios from "axios";

const fetch = () => axios.get("api/v1/certificates");
const create = payload => axios.post("api/v1/certificates", payload);
const update = (id, payload) => axios.put(`api/v1/certificates/${id}`, payload);
const destroy = payload =>
  axios.post("api/v1/certificates/bulk_destroy", payload);

const certificatesApi = {
  fetch,
  create,
  update,
  destroy,
};

export default certificatesApi;
