import React, { useState } from "react";

import { Alert } from "neetoui";

import certificatesApi from "apis/certificates";

const DeleteAlert = ({
  refetch,
  onClose,
  selectedCertificateIds,
  setSelectedCertificateIds,
}) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await certificatesApi.destroy({ ids: selectedCertificateIds });
      onClose();
      setSelectedCertificateIds([]);
      refetch();
    } catch (error) {
      logger.error(error);
      setDeleting(false);
    }
  };

  return (
    <Alert
      isOpen
      isSubmitting={deleting}
      message="Are you sure you want to continue? This cannot be undone."
      title={`Delete ${selectedCertificateIds.length} ${
        selectedCertificateIds.length > 1 ? "certificates" : "certificate"
      }?`}
      onClose={onClose}
      onSubmit={handleDelete}
    />
  );
};

export default DeleteAlert;
