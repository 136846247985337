import Login from "components/Authentication/Login";
import Dashboard from "components/Dashboard";
import Certificates from "components/Dashboard/Certificates/index";
import Settings from "components/Dashboard/Settings";

export const DASHBOARD_PATH = "/";
export const CERTIFICATES_PATH = "/certificates";
export const CHANGE_PASSWORD_PATH = "/settings?tab=password";
export const PROFILE_PATH = "/settings?tab=profile";
export const SETTINGS_PATH = "/settings";
export const LOGIN_PATH = "/login";
export const RESET_PASSWORD_PATH = "/my/password/new";

export const AUTH_ROUTES = [
  {
    path: LOGIN_PATH,
    component: Login,
  },
];

export const PRIVATE_ROUTES = [{ path: DASHBOARD_PATH, component: Dashboard }];

export const DASHBOARD_ROUTES = [
  {
    path: CERTIFICATES_PATH,
    component: Certificates,
  },
  {
    path: SETTINGS_PATH,
    component: Settings,
  },
];
