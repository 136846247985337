/* eslint-disable react/jsx-filename-extension */
import React from "react";

import { Tag } from "@bigbinary/neetoui";
import { Check } from "neetoicons";
import * as yup from "yup";

export const CERTIFICATES_FORM_INITIAL_FORM_VALUES = {
  name: "",
  domains: "",
};

export const CERTIFICATES_FORM_VALIDATION_SCHEMA = yup.object().shape({
  name: yup
    .string()
    .required("Title is required")
    .matches(/^[a-z-]+$/, "Only lowercase characters, and dashes are allowed"),
  domains: yup.string().required("Description is required"),
});

export const CERTIFICATES_TABLE_COLUMN_DATA = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "20%",
  },
  {
    title: "Domains",
    dataIndex: "domains",
    key: "domains",
    width: "30%",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: status => (
      <div className="flex flex-row items-center">
        <Tag label={status} style="secondary" />
      </div>
    ),
    key: "status",
    width: "10%",
  },
  {
    title: "Platform",
    dataIndex: "platform",
    render: (_, certificate) => (
      <div className="flex flex-row items-center">
        {certificate && certificate.connected_to_heroku && (
          <Tag label="Heroku" style="secondary" />
        )}
        {certificate && certificate.connected_to_neeto_deploy && (
          <Tag label="neetoDeploy" style="secondary" />
        )}
        {certificate &&
          !certificate.connected_to_neeto_deploy &&
          !certificate.connected_to_heroku && (
            <Tag label="Not connected" style="secondary" />
          )}
      </div>
    ),
    key: "platform",
    width: "15%",
  },
  {
    title: "Expires on",
    dataIndex: "valid_until",
    render: valid_until => {
      const today = new Date();
      const sixDaysFromNow = new Date();
      sixDaysFromNow.setDate(today.getDate() + 6);

      const targetDate = new Date(valid_until);

      if (!valid_until) {
        return (
          <div className="flex flex-row items-center">
            <Tag label={valid_until} style="secondary" />
          </div>
        );
      }

      if (
        (targetDate >= today && targetDate <= sixDaysFromNow) ||
        today >= targetDate
      ) {
        return (
          <div className="flex flex-row items-center">
            <Tag label={valid_until} style="danger" />
          </div>
        );
      }

      return (
        <div className="flex flex-row items-center">
          <Tag label={valid_until} style="secondary" />
        </div>
      );
    },
    key: "valid_until",
    width: "10%",
  },
  {
    title: "Autorenewal",
    dataIndex: "auto_renewal",
    render: () => (
      <div className="flex flex-row items-center">
        <Check />
      </div>
    ),
    key: "auto_renewal",
    width: "15%",
  },
];
