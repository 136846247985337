import React from "react";

import { Formik, Form as FormikForm } from "formik";
import { Button, Pane } from "neetoui";
import { Input, Textarea } from "neetoui/formik";

import certificatesApi from "apis/certificates";

import { CERTIFICATES_FORM_VALIDATION_SCHEMA } from "../constants";

const Form = ({ onClose, refetch, certificate, isEdit }) => {
  const handleSubmit = async values => {
    try {
      if (isEdit) {
        await certificatesApi.update(certificate.id, values);
      } else {
        await certificatesApi.create(values);
      }
      refetch();
      onClose();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Formik
      initialValues={certificate}
      validationSchema={CERTIFICATES_FORM_VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <FormikForm className="w-full">
          <Pane.Body className="space-y-6">
            <Input
              required
              className="w-full flex-grow-0"
              label="Name"
              name="name"
            />
            <Input
              required
              className="w-full flex-grow-0"
              label="Domains"
              name="domains"
            />
            {isEdit && (
              <>
                <Textarea
                  disabled
                  className="w-full flex-grow-0"
                  label="tls.crt"
                  name="tls_crt"
                  rows={10}
                />
                <Textarea
                  disabled
                  className="w-full flex-grow-0"
                  label="tls.key"
                  name="tls_key"
                  rows={10}
                />
              </>
            )}
          </Pane.Body>
          <Pane.Footer>
            <Button
              className="mr-3"
              disabled={isSubmitting}
              label={isEdit ? "Renew Certificate" : "Save changes"}
              loading={isSubmitting}
              style="primary"
              type="submit"
            />
            <Button label="Cancel" style="text" onClick={onClose} />
          </Pane.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
