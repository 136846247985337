import React from "react";

import { Pane, Typography } from "neetoui";

import Form from "./Form";

const Edit = ({ fetchCertificates, showPane, setShowPane, certificate }) => {
  const onClose = () => setShowPane(false);

  return (
    <Pane isOpen={showPane} onClose={onClose}>
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          Edit Certificate
        </Typography>
      </Pane.Header>
      <Form
        isEdit
        certificate={certificate}
        refetch={fetchCertificates}
        onClose={onClose}
      />
    </Pane>
  );
};

export default Edit;
